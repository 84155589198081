import useLocale from '@hooks/useLocale';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import { News } from '@type-declarations/overviewItems';
import { isAnchrDomain } from '@utils/getDomain';
import getInternalRoute from '@utils/getInternalRoute';

export default function NewsItem({ item }: { item: News }) {
  const locale = useLocale();
  const t = useTranslations();
  const {
    store: { host },
  } = useStore();
  let generalNewsLabel: string = t.tagsFilter.generalNews;
  if (isAnchrDomain(host)) {
    generalNewsLabel = t.tagsFilter.generalNewsAnchr;
  }
  const subtitle = item.solutions?.length
    ? item.solutions[0].title
    : generalNewsLabel;

  return (
    <Card
      title={item.overviewInfo.title || item.title}
      subtitle={subtitle}
      ctaLabel={t.readMore}
      image={item.overviewInfo.image}
      focusPoint={item.overviewInfo.focusPoint}
      href={getInternalRoute({
        internalLink: { page: [item] },
        locale,
      })}
    />
  );
}
