import Icon from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import Tags from '@atoms/Tags/Tags';
import { DEFAULT_FOCUS_POINT, FOCUS_POINT } from '@constants/constants';
import useStore from '@hooks/useStore';
import GradientMask from '@img/gradient-mask.png';
import GradientMaskNimbl from '@img/gradient-mask-nimbl.png';
import { CardSection as CardProps } from '@type-declarations/card';
import { isNimblDomain } from '@utils/getDomain';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './BigCard.module.scss';

export default function BigCard({ card }: CardProps) {
  const {
    store: { locale, host },
  } = useStore();
  const { id, image, page, focusPoint } = card;
  const { title, solutions } = page[0];
  let GradientMaskImage = GradientMask;

  if (isNimblDomain(host)) {
    GradientMaskImage = GradientMaskNimbl;
  }

  return (
    <div className={styles.card} key={id}>
      <figure className={styles.figure}>
        <Image
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          alt={image.description || ''}
          layout="fill"
          objectFit="cover"
          objectPosition={FOCUS_POINT[focusPoint || DEFAULT_FOCUS_POINT]}
          className={styles.image}
          quality={100}
        />

        <Image src={GradientMaskImage} layout="fill" objectFit="cover" alt="" />
      </figure>

      <div className={styles.content}>
        {solutions && <Tags tags={solutions} />}
        <h2 className={clsx('u-mb-0', styles.title)}>{title}</h2>
      </div>

      <Link
        href={getInternalRoute({
          internalLink: { page: card.page },
          locale,
        })}
        title={title}
        className={clsx('u-umbrella-link', styles.link)}
      />

      <span className={styles.button}>
        <Icon icon="arrow-right" className={styles.icon} />
      </span>
    </div>
  );
}
